import React from 'react'
import Form from './partials/NetlifyForm'
import Submit from './partials/Submit'

const ContactUsForm = () => {
  return (
    <Form
      className="c-contactUsForm"
      formName="Contact Us Form"
      successPage="/contact-us/success"
    >
      <h2 className="c-contactUsForm__header">Pop us a message</h2>

      <div className="form-group row">
        <label
          htmlFor="name"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
        >
          Your name*
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label
          htmlFor="email"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
        >
          Your email*
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label
          htmlFor="company"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
        >
          Company name*
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
          <input
            type="text"
            className="form-control"
            id="company"
            name="company"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label
          htmlFor="telephone"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
        >
          Phone number*
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
          <input
            type="tel"
            className="form-control"
            id="telephone"
            name="telephone"
            required
          />
        </div>
      </div>

      <div className="form-group row mb-4">
        <label
          htmlFor="message"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 col-form-label"
        >
          Your Message*
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8 col-xl-9">
          <textarea
            className="form-control"
            id="message"
            name="message"
            rows="10"
            required
          ></textarea>
        </div>
      </div>

      <Submit />
    </Form>
  )
}

export default ContactUsForm
