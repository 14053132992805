import React from 'react'
import { Container } from '../../components/elements'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import PageIntro from '../../components/PageIntro'
import ContactUsForm from '../../components/forms/ContactUs'
import Newsletter from '../../components/Newsletter'

const ContactUsPage = () => {
  return (
    <Page className="p-contact-us has-fingerPrints">
      <SEO title="Contact Erudus, send us a message or give us a call" />
      <Schema
        breadcrumbs={[
          {
            name: 'Contact Us',
            uri: '/contact-us',
          },
        ]}
      />

      <PageIntro
        title={`<strong>Contact</strong> us`}
        text={`<strong>Get in touch</strong> with our team if you have any questions, or to book a free demo. We're always here to help.`}
        image={`contact/Contact-Icon`}
      />

      <section className="c-section c-section--contact-us">
        <Container className="c-section__outer mb-lg-4">
          <div className="c-section__inner pt-0 pt-lg-5 pb-3 pb-lg-5">
            <div className="row">
              <div className="c-contactSide col-12 col-lg-3">
                <div className="c-contactSideItem">
                  <h3 className="c-contactSideItem__header">
                    <img
                      src="/icons/phone-dark.svg"
                      className="c-icon c-icon--phone mr-1"
                    />{' '}
                    Call us
                  </h3>
                  <h4>0333 121 89 99</h4>
                  <p className="c-contactSideItem__text">
                    <strong>Monday - Friday</strong> 9am - 5pm
                  </p>
                </div>
                <div className="c-contactSideItem">
                  <h3 className="c-contactSideItem__header">
                    <img
                      src="/icons/map-pointer-dark.svg"
                      className="c-icon c-icon--phone mr-1"
                    />{' '}
                    Find us
                  </h3>
                  <p className="c-contactSideItem__text">
                    Erudus Limited
                    <br />
                    Panther House
                    <br />
                    Asama Court
                    <br />
                    Newcastle Business Park
                    <br />
                    Newcastle Upon Tyne
                    <br />
                    NE4 7YD
                  </p>
                </div>
                <div className="c-contactSideItem pr-4">
                  <h3 className="c-contactSideItem__header">
                    <img
                      src="/icons/news-dark.svg"
                      width="19"
                      className="c-icon mr-1"
                    />{' '}
                    Media queries
                  </h3>
                  <p className="c-contactSideItem__text">
                    For media or PR queries please email{' '}
                    <a className="c-link" href="mailto:press@erudus.com">
                      press@erudus.com
                    </a>
                  </p>
                </div>
                <div className="c-contactSideItem mb-lg-0">
                  <h3 className="c-contactSideItem__header">
                    <img
                      src="/icons/bubble-dark.svg"
                      width="19"
                      className="c-icon mr-1"
                    />{' '}
                    Connect with us
                  </h3>
                  <ul className="c-socialLinks c-socialLinks--contact">
                    <li className="c-socialLinks__item">
                      <a
                        className="c-socialLinks__link"
                        href="https://www.facebook.com/ErudusUK/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="c-socialLinks__icon"
                          srcSet="/images/icons/Social/Facebook.svg"
                          src="/images/icons/Social/Facebook.png"
                          alt="Facebook"
                        />
                      </a>
                    </li>

                    <li className="c-socialLinks__item">
                      <a
                        className="c-socialLinks__link"
                        href="https://www.linkedin.com/company/erudusuk"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="c-socialLinks__icon"
                          srcSet="/images/icons/Social/LinkedIn.svg"
                          src="/images/icons/Social/LinkedIn.png"
                          alt="LinkedIn"
                        />
                      </a>
                    </li>

                    <li className="c-socialLinks__item">
                      <a
                        className="c-socialLinks__link"
                        href="https://twitter.com/erudusuk"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="c-socialLinks__icon"
                          srcSet="/images/icons/Social/Twitter.svg"
                          src="/images/icons/Social/Twitter.png"
                          alt="Twitter"
                        />
                      </a>
                    </li>

                    <li className="c-socialLinks__item">
                      <a
                        className="c-socialLinks__link"
                        href="https://www.instagram.com/erudusuk"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="c-socialLinks__icon"
                          srcSet="/images/icons/Social/Instagram.svg"
                          src="/images/icons/Social/Instagram.png"
                          alt="Instagram"
                        />
                      </a>
                    </li>

                    <li className="c-socialLinks__item">
                      <a
                        className="c-socialLinks__link"
                        href="https://github.com/Erudus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="c-socialLinks__icon"
                          srcSet="/images/icons/Social/Github.svg"
                          src="/images/icons/Social/Github.png"
                          alt="GitHub"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="c-contactUsDivider col-12 col-md-1">
                <span></span>
              </div>

              <div className="col-12 col-lg-7 col-xl-8">
                <ContactUsForm />
              </div>
            </div>
          </div>
        </Container>
      </section>

      <Newsletter />
    </Page>
  )
}

export default ContactUsPage
